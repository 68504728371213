<template>
  <div v-loading.fullscreen.lock="loading"></div>
</template>

<script>
import "@/service/Company";
import  AuthService  from "@/service/oidcService";

export default {
  data() {
    return {
      loading: true,
      data: null
    };
  },
  computed: {
    useroidc() {
      return this.$store.state.useroidc;
    }
  },
  async mounted() {
    try {
      let result = await AuthService.signinSilentCallback()
        .then(user => {
          this.$store.commit("SET_USER_OIDC", user);
          return user;
        })
        .catch(error => {});
    } catch (e) {
      this.data = "Error";
      this.loading = false;
    }
  }
};
</script>