// @ts-nocheck
import Vue from "vue";
import { HTTP } from "@/service/axios";
var errorMsg = {
  data: {
    data: "",
  },
  success: false,
};
Vue.mixin({
  methods: {
    async checkCompanyData(user) {
      if (
        user.cateIndustryType == null ||
        user.industryType == null ||
        user.corporateNumber == null ||
        user.corporateNumber == "" ||
        user.operatorType == null ||
        user.operatorType == "" ||
       ( user.operatorType == 3 && user.exportValue == 0 )||
       ( user.operatorType == 3 && user.exportValue == 1) ||
        (user.operatorType == 3 &&user.exportValue == 2 )||
       ( user.operatorType == 3 && user.shipment == 2 )||
       ( user.operatorType == 3 && user.shipment == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
});
